<template>
  <header>
    <nav>
      <div class='nav-wrapper container'>
        <a href="#" class="brand-logo">&nbsp;<img class="circle" width="50" src="./assets/logo.svg" alt="logo"/>Caption Cafe</a>

        <a href="#" data-target="nav-mobile" class="sidenav-trigger"><i class="material-icons">menu</i></a>
        <ul class="right hide-on-med-and-down">
          <li>
            <router-link to="/">
              <i class="material-icons left">home</i>Home
            </router-link>
          </li>

          <li>
            <router-link to="/pricing">
              <i class="material-icons left">attach_money</i>Pricing
            </router-link>
          </li>

          <li>
            <router-link to="/job">
              <i class="material-icons left">closed_caption</i>Caption Job
            </router-link>
          </li>

          <li>
            <router-link to="/auth">
              <a v-if="authState !== 'signedin'">Sign Up / Sign In</a>
              <a v-if="authState === 'signedin'"><i class="material-icons left">account_box</i>{{ user.username }}</a>
            </router-link>
          </li>
        </ul>

        <!-- Mobile menu -->
        <ul id="nav-mobile" class="sidenav">
          <li>
            <router-link to="/"><i class="material-icons left">home</i>Home</router-link>
          </li>

          <li>
            <router-link to="/pricing"><i class="material-icons">attach_money</i>Pricing</router-link>
          </li>

          <li>
            <router-link to="/job"><i class="material-icons">closed_caption</i>Caption Job</router-link>
          </li>

          <li>
            <router-link to="/auth"><i class="material-icons left">account_box</i>User</router-link>
          </li>
        </ul>
        <!-- /Mobile menu -->

      </div>
    </nav>
  </header>

  <main class="container">
    <router-view></router-view>
  </main>

  <footer class="page-footer">
    <!-- div class="container">
      <div class="row">
        <div class="col l4 offset-l2 s12">
          <h5 class="white-text">Links</h5>
          <ul>
            <li><a class="grey-text text-lighten-3" href="#!">Link 1</a></li>
          </ul>
        </div>
      </div>
    </div -->
    <div class="footer-copyright">
      <div class="container">
        © Caption Cafe, All rights reserved.
        <a class="grey-text text-lighten-4 right" href="mailto:feedback@caption.cafe">Feedback</a>
      </div>
    </div>
  </footer>
</template>

<script>
import { onAuthUIStateChange } from '@aws-amplify/ui-components'
import { provideI18n } from "@/i18nPlugin";
import M from 'materialize-css'
import zhTW from '@/i18n/zh-TW'
import enUS from '@/i18n/en-US'

export default {
  name: 'App',
  components: {
      // empty
  },
  created() {
    this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData;
    })

    // Create mobile nav bar
    document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('#nav-mobile');
        M.Sidenav.init(elems);
    });
  },
  data() {
    return {
      user: undefined,
      authState: undefined,
      unsubscribeAuth: undefined
    }
  },
  setup() {
    provideI18n({
      locale: 'zh-TW',
      messages: {
        'zh-TW': zhTW,
        'en-US': enUS
      }
    })
  },
  beforeUnmount() {
    this.unsubscribeAuth();
  }
}
</script>

<style>
@import './assets/materialize.min.css';
@import './assets/icon.css';

nav {
  background-color: #286DA8;
}

footer {
  background-color: #B37D4E;
}

.page-footer {
  background-color: #B37D4E;
}

main {
  flex: 1 0 auto;
}

#app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
</style>
