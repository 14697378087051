<template>
  <div class="section no-pad-bot" id="index-banner">
    <div class="container">
      <br><br>
      <h1 class="header center cyan-text text-darken-4">Caption Cafe</h1>
      <div class="row center">
        <h5 class="header col s12 light">{{ i18n.$t('homeIntroHightlight') }}</h5>
      </div>
      <div class="row center">.
        <router-link to="/job">
          <a href="#" class="btn-large waves-effect waves-light red lighten-1">{{ i18n.$t('homeGetStarted') }}</a>
        </router-link>
      </div>
    </div>
  </div>

    <div class="section">

      <!--   Icon Section   -->
      <div class="row">
        <div class="col s12 m4">
          <div class="icon-block">
            <h2 class="center brown-text"><i class="material-icons large">flash_on</i></h2>
            <h5 class="center">Speeds up creating subtitle</h5>

            <p class="light">{{ i18n.$t('homeIntroSpeedUp') }}</p>
          </div>
        </div>

        <div class="col s12 m4">
          <div class="icon-block">
            <h2 class="center brown-text"><i class="material-icons large">closed_caption</i></h2>
            <h5 class="center">SRT ready</h5>

            <p class="light">{{ i18n.$t('homeIntroSRT') }}</p>
          </div>
        </div>

        <div class="col s12 m4">
          <div class="icon-block">
            <h2 class="center brown-text"><i class="material-icons large">movie</i></h2>
            <h5 class="center">Machine Learning</h5>

            <p class="light">{{ i18n.$t('homeIntroML') }}</p>
          </div>
        </div>
      </div>

    </div>
</template>

<script>
import { useI18n } from "@/i18nPlugin";

export default {
  name: 'Home',
  setup() {
    return { i18n: useI18n() }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
