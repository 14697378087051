<template>
    <div class="section no-pad-bot" id="index-banner">
        <div class="container">
            <br><br>
            <h1 class="header center cyan-text text-darken-4">{{ i18n.$t('pricing') }}</h1>
            <div class="row center">
                <h5 class="header col s12 light">{{ i18n.$t('homeIntroHightlight') }}</h5>
            </div>
            <div class="row center">.
                <router-link to="/job">
                    <a href="#" class="btn-large waves-effect waves-light red lighten-1">{{ i18n.$t('homeGetStarted') }}</a>
                </router-link>
            </div>
        </div>
    </div>

    <div class="section">

        <div class="row" v-if="authData !== undefined">
            <div class="card">
                <div class="card-image waves-effect waves-block waves-light">
                    <img class="activator" src="credit-cover.jpeg">
                </div>
                <div class="card-content">
                    <span class="card-title activator grey-text text-darken-4">Points: {{ response }}<i class="material-icons right">more_vert</i></span>
                    <p><a href="#" @click="getUserCredit">Reload</a></p>
                </div>
                <div class="card-reveal">
                    <span class="card-title grey-text text-darken-4">What is this?<i class="material-icons right">close</i></span>
                    <p>這裡顯示的是你剩餘的可用點數 Your remaining caption cafe point.</p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col s12 m4">
                <div class="card">
                    <div class="card-content center">
                        <h5 class=''>Shorts</h5>
                    </div>
                    <div class="card-content center">
                        <h2 class='blue-text'><small>$</small>99</h2>
                    </div>

                    <ul class='collection center'>
                        <li class='collection-item'>
                            Short videos (TikTok)
                        </li>
                        <li class='collection-item'>
                            NT$ 2 (1 minute)
                        </li>
                    </ul>

                    <div class="card-content center">
                        <div class="row">
                            <div class="col s12">
                                <a v-if="authData !== undefined" :href="'https://pay.caption.cafe/ecpay/create_payment?amount=99&&user_uuid=' + authData.attributes.sub" class='btn green'>{{ i18n.$t('select') }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col s12 m4">
                <div class="card">
                    <div class="card-content center">
                        <h5 class=''>Business</h5>
                    </div>
                    <div class="card-content center">
                        <h2 class='blue-text'><small>$</small>299</h2>
                    </div>

                    <ul class='collection center'>
                        <li class='collection-item'>
                            YouTube, Vlog
                        </li>
                        <li class='collection-item'>
                            NT$ 2 (1 minute)
                        </li>
                    </ul>

                    <div class="card-content center">
                        <div class="row">
                            <div class="col s12">
                                <a v-if="authData !== undefined" :href="'https://pay.caption.cafe/ecpay/create_payment?amount=299&&user_uuid=' + authData.attributes.sub" class='btn green'>{{ i18n.$t('select') }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col s12 m4">
                <div class="card">
                    <div class="card-content center">
                        <h5 class=''>Professional</h5>
                    </div>
                    <div class="card-content center">
                        <h2 class='blue-text '><small>$</small>499</h2>
                    </div>

                    <ul class='collection center'>
                        <li class='collection-item'>
                            Producer
                        </li>
                        <li class='collection-item'>
                            NT$ 2 (1 minute)
                        </li>
                    </ul>

                    <div class="card-content center">
                        <div class="row">
                            <div class="col s12">
                                <a v-if="authData !== undefined" :href="'https://pay.caption.cafe/ecpay/create_payment?amount=499&&user_uuid=' + authData.attributes.sub" class='btn green'>{{ i18n.$t('select') }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col s12 center-align"><span><i class="material-icons">payment</i> (交易皆使用綠界科技 ECPay 支付並轉往綠界支付授權頁面, 提供 VISA, MasterCard, JCB 及銀行轉帳等方法, 交易過程均使用 SSL 安全機制)</span></div>

        </div>
    </div>
</template>

<script>
import { API, Auth } from 'aws-amplify';
import { useI18n } from "@/i18nPlugin";

export default {
    name: 'PriceComponet',
    setup() {
        return { i18n: useI18n() }
    },
    created() {
      if (this.authState === undefined) {
        Auth.currentAuthenticatedUser().then(authData => {

          // Get user detail
          // console.log(authData)

          this.authData = authData
          if (authData !== undefined) {
            this.getUserCredit()
          }
        });
      }
    },
    data() {
      return {
          response: undefined,
          authState: undefined,
          authData: undefined
      }
    },
    methods: {
      getUserCredit() {
        const apiName = 'ServiceEndpoint';
        const path = '/credit';
        const payload = {
          body: {}, // replace this with attributes you need
          headers: {}, // OPTIONAL
        };

        API.get(apiName, path, payload)
           .then(response => {

             if (response != undefined && response == 'False') {
               this.response = '0'
             } else {
               this.response = response
             }
           })
           .catch(error => {
             this.response = 'Oops, something went wrong when loading credit: ' + error.response
             console.log(error.response);
           });
      }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
