import { createRouter, createWebHistory } from 'vue-router';
import { Auth } from 'aws-amplify'

// Router modules
import Home from './components/Home';
import PriceComponet from './components/Price';
import JobComponent from './components/Job';
import JobDetailComponent from './components/JobDetail';
import AuthComponent from './components/Auth';


const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: { requiresAuth: false}
    },
    {
      path: '/pricing',
      name: 'Price',
      component: PriceComponet,
      meta: { requiresAuth: false}
    },
    {
      path: '/auth',
      name: 'Auth',
      component: AuthComponent,
      meta: { requiresAuth: false}
    },
    {
      path: '/job',
      name: 'Job',
      component: JobComponent,
      meta: { requiresAuth: true}
    },
    {
      path: '/job/:jobId',
      name: 'JobDetail',
      component: JobDetailComponent,
      meta: { requiresAuth: true}
    },
  ]
});


router.beforeResolve((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        Auth.currentAuthenticatedUser().then(() => {
            next()
        }).catch(() => {
            next({
                path: '/auth'
            });
        });
    } else {
        next()
    }
})

export default router
