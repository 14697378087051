import { createApp } from 'vue'
import Amplify from 'aws-amplify';
import axios from 'axios'

import App from './App.vue'
import router from './router';
import aws_exports from './aws-exports';


import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';

Amplify.configure(aws_exports);
applyPolyfills().then(() => {
  defineCustomElements(window);
});

const app = createApp(App)

app.use(router, axios)
app.mount('#app')
