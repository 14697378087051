<template>
  <div>
    <h3>Job: {{ job_id }}</h3>

    <form action="#" class="col s12">
      <div class="row">
        <div class="input-field col s12">
          <select>
            <option value="zh-TW">zh-TW - Traditional Chinese (Taiwan)</option>
          </select>
          <label>Video Language</label>
        </div>
      </div>

      <div class="row">
        <div class="file-field input-field col s12">
          <div class="btn btn-large">
            <span>File
                <i class="material-icons right">file_upload</i>
            </span>
            <input accept="video/mp4" type="file" ref="file" name="video" @change="handleFileUpload" class="form-control-file">
          </div>
          <div class="file-path-wrapper">
            <input class="file-path validate" type="text">
          </div>
          <span class="helper-text" data-error="wrong" data-success="right">File cannot exceed 5G, must be .mp4</span>
        </div>
     </div>

     <div class="row">
       <button @click="uploadVideo" class="btn btn-large waves-effect waves-light right cyan darken-1" type="submit" name="action" :disabled="loading">Submit
           <i class="material-icons right">send</i>
       </button>
     </div>
    </form>

    <div class="row">
      <div class="progress" v-if="loading">
        <div class="indeterminate"></div>
      </div>

      <div class="card-panel red lighten-2" v-if="response"><span class="grey-text text-lighten-4">{{ response }}</span></div>

      <br/>

      <div class="progress" v-if="upload_progress">
        <div class="determinate blue darken-3" :style="progressBarStyle"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { API } from 'aws-amplify';
import axios from 'axios';
import M from 'materialize-css'

export default {
  name: 'Job',
  data() {
    return { job_id: this.$route.params.jobId, response: undefined, loading: false, s3_presigned_upload_link: undefined, file: undefined, upload_progress: 0 }
  },
  created() {
  },
  computed: {
    progressBarStyle() {
      return { 'width': this.upload_progress.toString() + '%' }
    }
  },
  mounted () {
    M.AutoInit()

    var elems = document.querySelectorAll('select');
    M.FormSelect.init(elems);
  },
  beforeUnmount() {
  },
  methods: {
    showLoader() {
      this.loading = true
    },
    hideLoader() {
      this.loading = false
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
      this.getJobUploadLink();
    },
    getJobUploadLink() {
      const apiName = 'ServiceEndpoint';
      const path = '/upload';
      const payload = {
        body: {}, // replace this with attributes you need
        headers: {}, // OPTIONAL
      };

      this.showLoader();

      API.get(apiName, path + '/' + this.job_id, payload)
         .then(response => {
           // this.response = response
           this.s3_presigned_upload_link = response['upload_url']
           this.hideLoader()
         })
         .catch(error => {
           this.response = 'Oops, something went wrong: ' + error.response.data
           console.log(error.response);
           this.hideLoader()
         });
    },
    uploadVideo() {

      if (this.s3_presigned_upload_link == undefined) {
          this.response = 'No link, please get the link first.'
          return
      }

      if (this.file == undefined) {
          this.response = 'No file, please select the file first.'
          return
      }

      this.showLoader();

      // See:
      //  - https://github.com/axios/axios/issues/382
      //  - https://stackoverflow.com/a/48647194
      //  - https://stackoverflow.com/a/59541675
      //
      // let formData = new FormData();
      // let axiosInstance = axios.create();
      // formData.append('file', this.file)
      // axiosInstance.defaults.headers.common = {};

      axios.put(this.s3_presigned_upload_link, this.file, {
          headers: { 'Content-Type': 'video/mp4' },
          onUploadProgress: function(progressEvent) {
            let percentCompleted = Math.round((progressEvent.loaded / progressEvent.total) * 100)
            this.upload_progress = parseInt(percentCompleted)
          }.bind(this)
        })
        .then(response => {
          this.hideLoader()
          // this.response = response
          console.log(response)
          M.toast({html: 'File uploaded! You can go back to the job list to check your job status.'})
        })
        .catch(error => {
          this.response = 'Oops, something went wrong: ' + error.response
          console.log(error.response);
          console.log(error);
          this.hideLoader()
        });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../assets/icon.css';
</style>
