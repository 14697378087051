import { Auth } from 'aws-amplify';

const awsauth = {
    Auth: {

        // REQUIRED - Amazon Cognito Region
        region: 'us-east-1',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'us-east-1_Xfn7Yr2Nu',

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '5cj09lcaq7bisv1n8nh6v5t54l',

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false
    },
    API: {
        endpoints: [
        {
            name: 'ServiceEndpoint',
            endpoint: 'https://axvnymnlg9.execute-api.us-east-1.amazonaws.com/prod/',
            custom_header: async () => {
                // Alternatively, with Cognito User Pools use this:
                return { Authorization: (await Auth.currentSession()).idToken.jwtToken }
            }
        },
        ]
    }
}

export default awsauth
Auth.configure(awsauth)
