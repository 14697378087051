<template>
  <h1>{{ i18n.$t('jobList') }}</h1>
  <div class="card-panel teal">
    <span class="white-text">{{ i18n.$t('jobStorageDescription') }}</span>
  </div>
  <div>
    <div class="fixed-action-btn">
      <a @click="createJob" :class="{disabled: loading}" class="btn-floating btn-large waves-effect waves-light red"><i class="material-icons">add</i></a>
      &nbsp;
      <a @click="getJobs" :class="{disabled: loading}" class="btn-floating btn-large waves-effect waves-light cyan"><i class="material-icons">refresh</i></a>
    </div>

    <div class="progress" v-if="loading">
      <div class="indeterminate"></div>
    </div>

    <table>
      <thead>
        <tr>
          <th>{{ i18n.$t('jobTableCreationTime') }}</th>
          <th>{{ i18n.$t('jobTableJobID') }}</th>
          <th>{{ i18n.$t('jobTableJobStatus') }}</th>
          <th>{{ i18n.$t('download') }}</th>
          <th>{{ i18n.$t('upload') }}</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="job in jobs" v-bind:key="job.item_id">
          <td>{{ new Date(job['CREATE_TIMESTAMP'] * 1000).toLocaleString() }}</td>
          <td>{{ job['TASK_ID'] }}</td>
          <td>{{ transcribeStatus(job['TASK_STATUS']) }}</td>
          <td>
              <div class="preloader-wrapper small active" v-if="downloading.includes(job['TASK_ID'])">
                <div class="spinner-layer spinner-green-only">
                  <div class="circle-clipper left">
                      <div class="circle"></div>
                  </div>
                  <div class="gap-patch">
                      <div class="circle"></div>
                  </div>
                  <div class="circle-clipper right">
                      <div class="circle"></div>
                  </div>
                </div>
              </div>

              <a v-if="! downloading.includes(job['TASK_ID'])" @click="download(job['TASK_ID'])" class="waves-effect waves-light btn-small" :class="{disabled: job['TASK_STATUS'] != 'COMPLETED'}"><i class="material-icons">file_download</i></a>
          </td>
          <td>
            <router-link :to="job['TASK_STATUS'] == 'NONE' ? `/job/${job['TASK_ID']}` : ''">
                <a class="btn-flat" :class="{disabled: job['TASK_STATUS'] != 'NONE'}"><i class="material-icons">more_horiz</i></a>
            </router-link>
          </td>
        </tr>
      </tbody>

    </table>
  </div>
</template>

<script>
import { API } from 'aws-amplify';
import M from 'materialize-css'
import { useI18n } from "@/i18nPlugin";

export default {
  name: 'Job',
  setup() {
    return { i18n: useI18n() }
  },
  data() {
    return { response: undefined, jobs: undefined, interval: undefined, loading: false, downloading: [] }
  },
  created() {
    this.getJobs()
    // this.interval = setInterval(this.getJobs, 60 * 1000) // poll data every 60 seconds
  },
  mounted () {
    M.AutoInit()
  },
  beforeUnmount() {
    if (this.interval) {
      clearInterval(this.interval)
      this.interval = undefined
    }
  },
  methods: {
    transcribeStatus(status) {
        let s = {
            'NONE': '尚未就緒',
            'PENDING': '任務排程中',
            'NO_CREDIT': '',
            'INSUFFICIENT_CREDIT': '',
            'INVALID_VIDEO_DURATION': '無法識別影片長度',
            'RUNNING': '運行中',
            'COMPLETED': '完成',
        }

        return (status in s ? s[status] : status)
    },
    showLoader() {
      this.loading = true
    },
    hideLoader() {
      this.loading = false
    },
    download(job_id) {
      const apiName = 'ServiceEndpoint';
      const path = '/download';
      const payload = {
        body: {}, // replace this with attributes you need
        headers: {}, // OPTIONAL
      };

      this.downloading.push(job_id)

      API.get(apiName, path + '/' + job_id, payload)
         .then(response => {
           this.response = response
           console.log(response)

           let downloading_item_index = this.downloading.indexOf(job_id)
           if (downloading_item_index > -1) {
             delete this.downloading[downloading_item_index]
           }

           let url = response['download_url']
           window.location.href = url
         })
         .catch(error => {
           this.response = 'Oops, something went wrong: ' + error.response
           console.log(error.response);
         });
    },
    createJob() {
      const apiName = 'ServiceEndpoint';
      const path = '/job';
      const payload = {
        body: {}, // replace this with attributes you need
        headers: {}, // OPTIONAL
      };

      this.showLoader()

      API.put(apiName, path, payload)
         .then(response => {
           this.response = response
           this.hideLoader()
           this.getJobs() // Refresh the job
         })
         .catch(error => {
           this.response = 'Oops, something went wrong: ' + error.response
           console.log(error.response);
           this.hideLoader()
         });
    },
    getJobs() {
      const apiName = 'ServiceEndpoint';
      const path = '/job';
      const payload = {
        body: {}, // replace this with attributes you need
        headers: {}, // OPTIONAL
      };

      this.showLoader()

      API.get(apiName, path, payload)
         .then(response => {
           this.jobs = response
           this.hideLoader()
         })
         .catch(error => {
           this.response = 'Oops, something went wrong: ' + error.response
           console.log(error.response);
           this.hideLoader()
         });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../assets/icon.css';
</style>
